import 'isomorphic-fetch'

interface IPrama {
  [key: string]: any
}
enum IEnv {
  DEV = 'DEV',
  BOE = 'BOE',
  PROD = 'PROD'
}
const API_ORIGINS = {
  DEV: '',
  BOE: '//ngcc-boe.bytedance.net',
  PROD: '//ngcc.bytedance.com'
}
const code = 'ocic'

const getSDKConfig = (env: string) => {
  return new Promise((resolve, reject) => {
    fetch(`${API_ORIGINS[env as IEnv]}/api/configure/sdk/agent_bar/config?package_name=@byted-cg/linkchat-sdk&access_code=${code}`, {
      method: 'get',
    })
      .then(res => res.json())
      .then(response => {
        const data = response.data
        if (data) {
          const {jsUrl} = data
          resolve(jsUrl)
        } else {
          reject(response.description)
        }
      })
      .catch(reject)
  })
}

const loadScript = (name: string, url: string) => 
  new Promise((resolve, reject) => {
    if ((window as IPrama)[name]) {
      return resolve((window as IPrama)[name])
    }
    const script = document.createElement('script')
    script.src = url
    script.onload = () => {
      if ((window as IPrama)[name]) {
        return resolve((window as IPrama)[name])
      }
      reject(`js加载完成，但是没找到[${name}]变量`)
    }
    script.onerror = () => {
      reject('js加载失败')
    }
    document.body.appendChild(script)
  })

export default (config: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const env = (config.env || 'prod').toLocaleUpperCase()
      const cdn = await getSDKConfig(env)
      const module = (await loadScript('Linkchat', cdn as string)) as any
      if (module) {
        const instance = new module(config);
        resolve(instance)
      }
    } catch(e) {
      reject(e)
    }
  })
}
